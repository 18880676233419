.updateContainer{
   padding:30px 0px 60px 0px
}


@media only screen and (max-width:600px){
    .updateText{
        font-size:30px;
        font-weight: 600;
        text-align:center;
        margin:20px 0px
    }

    .blogContainer{
        padding:30px 15px;
        max-width:350px;
        margin:auto;
        cursor: pointer;
    }

    .updateTitle{
        font-size:27px;
        font-weight: 500;
        padding:10px 0px
    }

    .categoryText{
        color:#FB3640;
        font-weight: 400;
    }
    
}

@media only screen and (min-width:600px){
    .updateText{
        font-size:34px;
        font-weight: 600;
        text-align:center
    }

    .updateTitle{
        font-size:28px;
        font-weight: 500;
        padding:10px 0px
    }

    .categoryText{
        color:#FB3640;
        font-weight: 400;
    }

    .blogContainer{
        padding:30px 15px;
        max-width:350px;
        margin:auto;
        cursor: pointer;
    }

    
}

@media only screen and (min-width:650px){
    .gridContainer{
        display: grid;
        grid-template-columns: 1fr 1fr
    }

}



@media only screen and (min-width:773px){
    .gridContainer{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr
    }

}


@media only screen and (min-width:1200px){
    .updateContainer{
        max-width:1130px;
        margin:auto
    }
}



@media only screen and (min-width:1600px){
    .updateContainer{
        max-width:1500px;
        margin:auto
    }
}



.exploreButton{
    color:#00B4D8;
    font-weight:600;
    border:1px solid #00B4D8
}

.exploreButton:hover{
    background:#00B4D8;
    color:white
}


.chip{
    background:#ecfaff;
    padding:6px 10px;
    text-align: center;
    font-size:12px;
    color:#00b4d8
}