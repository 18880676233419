@media only screen and (max-width: 600px) {
  .kuleanPayHero {
    margin: 150px 0px 100px 0px;
    text-align: center;
  }
  .kuleanPayHero h2 {
    font-size: 40px;
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    padding: 30px 0px 0px 0px;
  }

  .kuleanchip {
    color: white;
    background: #fb3640;
    width: 110px;
    border-radius: 100px;
    padding: 6px 30px 6px 20px;
    font-size: 14px;
  }
}


@media only screen and (min-width:600px){
    .kuleanPayHero {
        margin: 150px 0px 100px 0px;
        text-align: center;
      }
      .kuleanPayHero h2 {
        font-size: 64px;
        font-weight: 700;
        line-height: 64px;
        max-width: 737px;
        margin:auto;
        padding: 30px 0px 0px 0px;
      }
    
      .kuleanchip {
        color: white;
        background: #fb3640;
        width: 110px;
        border-radius: 100px;
        padding: 6px 30px 6px 20px;
        font-size: 14px;
      }

      .kuleanPayHero img{
        width:483px;
        height:385px;
        margin:auto
      }
}


@media only screen and (min-width:768px){
    .kuleanPayHero {
        margin: 150px 0px 100px 0px;
        text-align: center;
      }
      .kuleanPayHero h2 {
        font-size: 64px;
        font-size: 40px;
        font-weight: 700;
        line-height: 64px;
        max-width: 737px;
        margin:auto;
        padding: 30px 0px 0px 0px;
      }
    
      .kuleanchip {
        color: white;
        background: #fb3640;
        width: 110px;
        border-radius: 100px;
        padding: 6px 30px 6px 20px;
        font-size: 14px;
      }

      .kuleanPayHero img{
        width:483px;
        height:385px;
        margin:auto
      }
}


@media only screen and (min-width:992px){
    .kuleanPayHero {
        margin: 150px 0px 100px 0px;
        text-align: center;
      }
      .kuleanPayHero h2 {
        font-size: 64px;
        font-size: 40px;
        font-weight: 700;
        line-height: 64px;
        max-width: 737px;
        margin:auto;
        padding: 30px 0px 0px 0px;
      }
    
      .kuleanchip {
        color: white;
        background: #fb3640;
        width: 110px;
        border-radius: 100px;
        padding: 6px 30px 6px 20px;
        font-size: 14px;
      }

      .kuleanPayHero img{
        width:483px;
        height:385px;
        margin:auto
      }
}


@media only screen and (min-width:1200px){
    .kuleanPayHero {
        margin: 150px 0px 100px 0px;
        text-align: center;
      }
      .kuleanPayHero h2 {
        font-size: 64px;
        font-weight: 700;
        line-height: 64px;
        max-width: 737px;
        margin:auto;
        padding: 30px 0px 0px 0px;
      }
    
      .kuleanchip {
        color: white;
        background: #fb3640;
        width: 110px;
        border-radius: 100px;
        padding: 6px 30px 6px 20px;
        font-size: 14px;
      }

      .kuleanPayHero img{
        width:483px;
        height:385px;
        margin:auto
      }
}