.articleContainer {
  max-width: 700px;
  margin: auto;
  padding: 30px 10px;
  background-color: white;
}

.articleTitle {
  font-size: 42px;
  font-weight: 900;
}

.article-author {
  font-weight: 600;
  margin: 0px;
  padding: 0px;
}

.article-meta {
  font-size: 13px;
  color: #6c6d6f;
}

.article-body p {
  padding: 1em 0px;
}

.article-body h1 {
  font-size: 3em;
  margin: 1em 0px 0px 0px;
}

.article-body h2 {
  font-size: 2em;
  margin: 1em 0px 0px 0px;
}

.article-body h3 {
  font-size: 1.6em;
  margin: 1em 0px 0px 0px;
}

.article-body h4 {
  font-size: 1.5em;
  font-weight: 600;
  margin: 1em 0px 0px 0px;
}

.article-body a {
  color: #00b4d8;
  text-decoration: underline;
}

.article-body img {
  margin: 10px;
  width: 100%;
  object-fit: cover;
}

.article-body figcaption {
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .blog-grid {
    display: grid;
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 600px) {
  .blog-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}


@media only screen and (min-width: 768px) {
  .blog-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}


@media only screen and (min-width: 996px) {
  .blog-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}