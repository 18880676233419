.beMoreTitle{
    font-weight:600;
    border-left:solid 2px #00B4D8;
    padding:0px 20px;
    font-size: 24px;
}

.beMore p{
    font-size:20px
}


.doMore p{
    font-size:20px
}

.textWidth{
    max-width:486px
}



@media only screen and (max-width:600px){
    .missionContainer{
        display:block;
        /* justify-content: space-between; */
        padding:50px 30px
    }
}


@media only screen and (min-width:600px){
    .missionContainer{
        display:block;
        /* justify-content: space-between; */
        padding:50px 30px
    }

    .bemoreImage{
        height:432px !important;
        width:486px !important;
        border-radius: 8px !important;
        object-fit: cover !important;
    }
}


@media only screen and (min-width:768px){
    .missionContainer{
        display:block;
        /* justify-content: space-between; */
        padding:40px 30px;
       
    }

    .bemoreImage{
        height:432px !important;
        width:486px !important;
        border-radius: 8px !important;
        object-fit: cover !important;
        
    }
}



@media only screen and (min-width:992px){
    .missionContainer{
        display:block;
        /* justify-content: space-between; */
        margin:auto auto auto auto;
        padding:60px 90px 90px 40px
    }
}

@media only screen and (min-width:1200px) {
    .missionContainer{
        display:flex;
        max-width: 1500px;
        /* margin:auto auto auto auto; */
        padding:90px 90px 90px 99px;
    }

    .doMore{
        margin:0px 0px 0px 70px
    }
}

@media only screen and (min-width:1600px){
    .missionContainer{
        display:flex;
        justify-content: space-between;
        max-width: 1500px;
        margin:auto;
        padding:96px 100px 90px 99px
    }

}
    
