.ecosystemText {
  color: black;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .bannerContainer {
    padding: 40px 0px 90px 0px;
    background: url("../../assets/images/aboutBackgroundImage.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
  }

  .whiteContainer {
    padding: 40px 0px 90px 0px;
    background: white;
  }

  .bodyContainer {
    padding: 40px 0px 90px 99px;
  }

  .navContainer {
    padding: 0px 0px 0px 0px;
  }

  .ecosystemContainer {
    margin: 0px 25px;
  }

  .ecosystemContainer p {
    max-width: 300px;
  }

  .ecosystemText {
    font-size: 50px;
    font-weight: bold;
    margin: 130px 0px 0px 0px;
    line-height: 50px;
  }

  .captionText {
    width: 100%;
    margin: 20px 0px 0px 0px;
    font-size: 20px;
  }
  .bannerFlex {
    display: block;
    justify-content: space-between;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .bannerContainer {
    padding: 20px 0px 90px 10px;
    background: url("../../assets/images/aboutBackgroundImage.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: auto;
  }

  .whiteContainer {
    padding: 20px 0px 90px 10px;
    background: white;
  }

  .bodyContainer {
    padding: 40px 0px 0px 10px;
  }

  .navContainer {
    margin: 0px 0px 0px 10px;
  }

  .ecosystemContainer {
    margin: 0px 30px;
  }

  .ecosystemText {
    font-size: 60px;
    font-weight: bold;
    margin: 120px 0px 0px 0px;
    line-height: 67px;
  }

  .captionText {
    width: 75%;
    margin: 20px 0px 0px 0px;
    font-size: 20px;
  }
  .bannerFlex {
    display: block;
    justify-content: space-between;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .captionText {
    width: 70%;
    margin: 20px 0px 0px 0px;
  }

  .ecosystemText {
    font-size: 60px;
    font-weight: bold;
    margin: 200px 290px 0px 0px;
    line-height: 69px;
    max-width: 700px;
  }

  .navContainer {
    padding: 0px 0px 0px 9px;
  }

  .bannerContainer {
    padding: 20px 0px 90px 0px;
    background: url("../../assets//images/aboutBackgroundImage.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .whiteContainer {
    padding: 20px 0px 90px 0px;
    background: white;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1184px) {
  .smallScreen {
    display: none;
  }
}

@media screen and (max-width: 1192px) {
  .largeScreen {
    display: none;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .bannerContainer {
    padding: 0px 0px 90px 99px;
    background: url("../../assets/images/aboutBackgroundImage.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .whiteContainer {
    padding: 0px 0px 90px 99px;
    background: white;
  }

  .bodyContainer {
    padding: 40px 0px 90px 99px;
  }

  .navContainer {
    padding: 0px 100px 0px 83px;
  }

  .ecosystemContainer {
    margin: 0px;
    max-width: 960px;
  }

  .bannerFlex {
    display: flex;
    justify-content: space-between;
  }

  .ecosystemContainer {
    width: 50%;
  }

  .ecosystemText {
    font-size: 60px;
    font-weight: bold;
    margin: 230px 0px 0px 0px;
    line-height: 69px;
    color: black;
  }

  .captionText {
    width: 69%;
    font-size: 20px;
    margin: 20px 0px 0px 0px;
    color: black;
  }
}

@media only screen and (min-width: 1500px) {
  .bannerContainer {
    padding: 0px 0px 90px 99px;
    background: url("../../assets/images/aboutBackgroundImage.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .whiteContainer {
    padding: 0px 0px 90px 99px;
    background: white;
  }

  .ecosystemText {
    font-size: 60px;
    font-weight: bold;
    margin: 250px 0px 0px 0px;
    line-height: 69px;
    color: black;
  }
}

@media only screen and (min-width: 1600px) {
  .navContainer {
    max-width: 1500px;
    margin: auto;
  }

  .bannerFlex {
    max-width: 1430px;
    margin: auto;
  }
}

@media only screen and (min-width: 1900px) {
  .bannerContainer {
    padding: 0px 0px 90px 99px;
    background: url("../../assets/images/aboutBackgroundImage.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .whiteContainer {
    padding: 0px 0px 90px 99px;
    background: white;
  }
}
