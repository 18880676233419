@media only screen and (max-width:400px){
    .infoContainer{
        padding:40px 20px
        }
        .infoHeading{
            font-size:27px !important;
            line-height: 27px;
            align-items: center;
        }
    }


@media only screen and (max-width:600px){
    .infoContainer{
        padding:70px 20px 60px 20px
        }
        .infoHeading{
            font-size:30px;
            align-items: center;
        }
    }

@media only screen and (min-width:600px){
.infoContainer{
    padding:40px 30px 40px 20px
    }
.infoHeading{
    font-size:40px
}
}


@media only screen and (min-width:768px){
    .infoContainer{
        padding:0px 50px 0px 9px
        }
    .infoFlex{
        display:flex;
        justify-content: space-between;
    }
}

@media only screen and (min-width:1200px) {
    .infoContainer{
        padding:0px 120px 0px 83px
        }
}


@media only screen and (min-width:1600px){
    .infoContainer{
        max-width:1530px;
        margin:auto
    }
}

.infoText{ 
    max-width: 512px;
}



.buttonPadding{
    padding:14px 28px;
    border-radius: 100px;
}