.brandTitle{
    text-align: center;
    font-size: 16px;
    font-weight: 900;
    text-transform: uppercase;
    padding:0px 0px 20px 0px
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .brandsContainer{
        padding:50px 0px;
        background:#EDFAFC;
    }
    .brandsFlex{
        display:flex;
        overflow-y: auto;
        overflow-x: scroll;
        padding:20px
    }
    .brandsFlex > img{
        margin:0px 0px 0px 20px;
        width:128.6px;
        height:32px
        
    }
}



/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .brandsFlex{
        display:flex;
        justify-content: space-between;
        padding:25px;
        overflow-x: scroll;
    }
    .brandsFlex > img{
        width:128.6px;
        height:32px;
        margin:0px 0px 0px 20px
    }

    .brandsContainer{
        padding: 50px 0px 0px 0px;
        background:#EDFAFC;
        width:100%
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .brandsFlex{
        display:flex;
        justify-content: space-between;
        padding:25px;
        overflow-y: auto;
        overflow-x: scroll;
        width:100%
    }
    .brandsFlex > img{
        width:128.6px;
        height:32px;
        margin:0px 0px 0px 30px
    }

    .brandsContainer{
        padding: 50px 0px 0px 0px;
        background:#EDFAFC;
        width:100%
    }
   
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .brandsFlex{
        display:flex;
        justify-content: space-between;
        padding:25px;
        overflow-y: auto;
        overflow-x: scroll;
        width:100%
    }
    .brandsFlex > img{
        margin:0px 0px 0px 20px;
        width:128.6px;
        height:32px
    }

    .brandsContainer{
        padding: 50px 0px 0px 0px;
        background:#EDFAFC;
        width:100%
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .brandsFlex{
        display:flex;
        justify-content: space-between;
        padding:25px 100px;
        overflow-x: hidden;
        margin:auto;
        
    }
    .brandsFlex > img{
        width:128.6px;
        height:32px;
        margin:0px 0px 0px 0px
    }

    .brandsContainer{
        padding: 40px 0px 30px 0px;
        background:#EDFAFC;
        width:100%
    }

}



@media only screen and (min-width: 1600px) {
    .brandsFlex{
        display:flex;
        justify-content: space-between;
        padding:25px 100px;
        overflow-x: hidden;
        max-width: 1500px;
        margin:auto
    }
}