.brandColor{
    color:#00B4D8
}


.smallGridWidth{
    width:90%
}


.largeGridWidth{
    width:86.7%
}

@media screen and (min-width:3000px){
    .largeFooter{
        display:none
    }
}

@media screen and (max-width:3000px){
    .smallFooter{
        display:none
    }
}


@media screen and (max-width:700px){
    .largeFooter{
        display:none
    }
    .smallFooter{
        display:block
    }
}




@media only screen and (min-width:1600px) {
    .largeGridWidth{
        max-width:1370px
    }

    .smallGridWidth{
        max-width:1400px;
        margin:auto
    }
}