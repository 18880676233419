.getStarted{
    background:#00B4D8;
    padding:14px 28px;
    border-radius: 56px;
    color:white;
    display:flex;
    transition: 0.3s;
}

.getStarted:hover{
    color:#1DBCDB;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2) !important;
}


.demoButton{
    background:#00B4D8;
    padding:14px 28px;
    border-radius: 56px;
    color:white;
    display:flex;
    transition: 0.3s;
}

.demoButton:hover{
    color:#1DBCDB;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2) !important;
}


.requestDemoButton{
    background:#00B4D8;
    padding:14px 20px;
    border-radius: 56px;
    color:white;
    transition: 0.3s;
    text-align:center;
    margin:10px 0px
}



.requestDemoButton:hover{
    color:#1DBCDB;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2) !important;
}




.primaryBtn{
    background:white;
    padding:14px 28px;
    border-radius: 10px;
    color:#00B4D8;
    display:flex;
    transition: 0.3s;
    max-height:50px
}


.secondaryBtn{
    border:1px solid white;
    padding:14px 28px;
    border-radius: 10px;
    color:white;
    display:flex;
    transition: 0.3s;
    max-height:50px
}