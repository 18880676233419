@media only screen and (max-width:410px){

    .aboutContainer{
        padding:40px 0px 90px 0px;
        background:url("../../assets/images/aboutBackgroundImage.png");
        background-position: cover;
        background-repeat: no-repeat;
        background-size:100% 100%;
        object-fit: contain
        }
  

    .missionCard{
        max-width:254px;
        margin:30px 0px 50px 0px;
        
    }

    .missionCard h2{
        font-size:32px;
        padding:10px 0px;
        font-weight:600 
    }

    .missionFlex{
        justify-content: space-between;
        width:100%
    }
}




@media only screen and (min-width:411px){
  

    .missionCard{
        max-width:254px;
        margin:30px 0px 50px 0px;
        
    }

    .missionCard h2{
        font-size:32px;
        padding:10px 0px;
        font-weight:600 
    }

    .missionFlex{
        display: flex;
        justify-content: space-between;
        width:100%
    }
}


@media only screen and (max-width:600px){
    .aboutContainer{
        padding:40px 0px 90px 0px;
        background:url("../../assets/images/aboutBackgroundImage.png");
        background-position: cover;
        background-repeat: no-repeat;
        background-size:100% 100%;
        object-fit: contain
        }


    .aboutFlex{
        padding:20px 0px 0px 22px
    }

    .aboutFlex h2{
        font-size:50px;
        color:#0096B4;
        font-weight:700;
        margin:50px 0px 30px 0px
    }

    .aboutFlex p{
        padding:0px 60px 0px 0px;
        max-width: 436px;
    }

    .hideImageMobile{
        display:none
    }

    .aboutBody{
        padding:40px 30px
    }

    .aboutCardFlex h2{
        font-size:40px;
        font-weight:600;
        padding:10px 0px
    }

    .whatWeDoContainer{
        padding:70px 0px 70px 0px;
        max-width:813px
    }

    .whatWeDoContainer h2{
        font-size:40px;
        font-weight:600;
       
    }

    .businessCategory{
        padding:15px 0px
    }

   

    .missionCard{
        max-width:254px;
        margin:30px 0px 50px 0px;
        /* text-align: center; */
    }

    /* .missionCard img{
        max-width: 50%;
        margin:auto
    } */

    .missionCard h2{
        font-size:32px;
        padding:10px 0px;
        font-weight:600 
    }

    .teamContainer{
        margin:100px 0px 40px 0px
    }


    .teamHeading h2{
        font-size:32px;
        font-weight:600 
    }

    .teamHeading p{
        font-size:15px;

    }

    .teamImage{
        width:252px;
        height:220px;
        margin:90px auto
    }
}


@media only screen and (min-width:600px){
    .aboutContainer{
        padding:20px 0px 90px 10px;
        background: url("../../assets/images/aboutBackgroundImage.png");
        background-position: cover;
        background-repeat: no-repeat;
        background-size:100% 100%;
        object-fit: contain
        }

    .aboutFlex{
        padding:20px 20px
    }

    .aboutFlex h2{
        font-size:50px;
        color:#0096B4;
        font-weight:700;
        margin:50px 0px 30px 0px
    }

    .aboutFlex p{
        padding:0px 60px 0px 0px;
        max-width: 436px;
    }

    .hideImageMobile{
        display:none
    }

    .aboutBody{
        padding:40px 30px
    }

    .aboutCardFlex h2{
        font-size:50px;
        font-weight:700
    }

    .aboutCardFlex p{
        max-width: 564px;
    }

    .whatWeDoContainer{
        padding:70px 0px 70px 0px
    }

    .whatWeDoContainer h2{
        font-size:40px;
        font-weight:600;
    }

    .businessCategory{
        padding:15px 0px
    }
    
    .customerCategory{
        display:grid;
        grid-template-columns: 1fr 1fr;
    }


    .missionCard{
        max-width:254px;
        margin:30px 0px 50px 0px;
        
    }

    .missionCard h2{
        font-size:32px;
        padding:10px 0px;
        font-weight:600 
    }

    .missionFlex{
        display: flex;
        justify-content: space-between;
        width:100%
    }

    .teamContainer{
        margin:50px auto;
        max-width:632px;
    }


    .teamHeading h2{
        font-size:32px;
        font-weight:600 
    }

    .teamHeading p{
        font-size:15px;
    }

    .teamMembers{
        margin:40px 0px
    }

    .teamImage{
        width:252px;
        height:220px;
        margin:40px 0px
    }
    .teamMembers{
        display:grid;
        grid-template-columns: 1fr 1fr;
    }
}




@media only screen and (min-width:768px){
    .aboutContainer{
        padding:20px 0px 90px 10px;
        background:url("../../assets/images/aboutBackgroundImage.png");
        background-position: cover;
        background-repeat: no-repeat;
        background-size:100% 100%;
        object-fit: contain
        }

    .aboutFlex{
        padding:20px 40px
    }

    .aboutDescription p{
        font-size: 20px
    }

    .aboutFlex h2{
        font-size:50px;
        color:#0096B4;
        font-weight:700;
        margin:50px 0px 30px 0px
    }

    .aboutFlex p{
        padding:0px 60px 0px 0px
    }

    .hideImageMobile{
        display:none
    }

    .aboutBody{
        padding:40px 50px
    }

    .aboutCardFlex h2{
        font-size:40px;
        font-weight:600;
        padding:10px 0px
    }

    .whatWeDoContainer{
        padding:70px 0px 70px 0px;
        max-width:813px
    }

    .whatWeDoContainer h2{
        font-size:40px;
        font-weight:600;
    }

    .customerCategory{
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    
    .missionCard{
        max-width:254px;
        margin:30px 0px 50px 0px;
        
    }

    .missionCard h2{
        font-size:32px;
        padding:10px 0px;
        font-weight:600 
    }

    .missionFlex{
        display: flex;
        justify-content: space-between;
        width:100%
    }

   

    .teamImage{
        width:252px;
        height:220px;
        margin:40px 0px
    }
    .teamMembers{
        display:grid;
        grid-template-columns: 1fr 1fr;
    }

    .teamHeading p{
        max-width: 580px;
    }
}


@media only screen and (min-width:992px){
    .aboutContainer{
        padding:0px 0px 90px 99px;
        background: url("../../assets/images/aboutBackgroundImage.png");
        background-position: stretch;
        background-repeat: no-repeat;
        background-size:100% 100%;
        }

    .aboutFlex{
        padding:20px 40px;
        display: flex;
    }

    .aboutFlex h2{
        font-size:60px;
        color:#0096B4;
        font-weight:700;
        margin:110px 0px 30px 0px
    }

    .aboutDescription{
        padding:140px 100px
    }

    .aboutDescription p{
        font-size: 20px;
    }

    .aboutFlex p{
        padding:0px 100px 0px 0px;
        font-size:20px
    }

    .aboutFlex p{
        padding:0px 60px 0px 0px
    }

    .hideImageMobile{
        display:block
    }

    .aboutBody{
        padding:40px 50px
    }

    .aboutCardFlex p{
        max-width: 448px;
    }

    .aboutCardFlex h2{
        font-size:40px;
        font-weight:600;
        padding:10px 0px
    }

    .whatWeDoContainer{
        padding:50px 0px 70px 0px
    }

    .whatWeDoContainer h2{
        font-size:40px;
        font-weight:600; 
    }

    
    .missionCard{
        max-width:254px;
        margin:30px 0px 50px 0px;
        
    }

    .missionCard h2{
        font-size:32px;
        padding:10px 0px;
        font-weight:600 
    }

    .missionFlex{
        display: flex;
        justify-content: space-between;
        width:100%
    }

   

    .teamImage{
        width:252px;
        height:220px;
        margin:40px 0px
    }
    .teamMembers{
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}


@media only screen and (min-width:1200px){
    .aboutContainer{
        padding:0px 0px 23px 99px;
        background: url("../../assets/images/aboutBackgroundImage.png");
        background-position: stretch;
        background-repeat: no-repeat;
        background-size:100% 100%;
        }



    .aboutFlex{
        padding:100px 0px;
        display: flex;
    }

    .aboutFlex h2{
        font-size:88px;
        color:#0096B4;
        font-weight:700;
        margin:100px 0px 30px 0px
    }

    .aboutDescription{
        padding:140px 0px 140px 50px;
    }

    .aboutDescription p{
        font-size: 20px;
    }

    .aboutFlex p{
        padding:0px 0px 0px 0px;
        font-size:20px
    }

    .hideImageMobile{
        display:block;
        padding-bottom: -900px;
    }

    .hideImageMobile img{
        width:500px;
       
    }

    .aboutBody{
        padding:0px 0px 90px 99px;
    }

    .aboutCardFlex {
        display:flex;
        padding:122px 0px
    }

    .aboutCardFlex p{
        max-width: 448px;
    }

    .aboutCardFlex h2{
        font-size:40px;
        font-weight:600;
        padding:10px 0px
    }

    .whatWeDoContainer h2{
        font-size:40px;
        font-weight:600;
        padding:10px 0px
    }

    .aboutCardText{
        margin:80px 
    }

    .whatWeDoContainer{
        padding:10px 0px 70px 0px
    }

    .whatWeDoContainer h2{
        font-size:40px;
        font-weight:600;
       
    }

    .missionCard{
        max-width:254px;
        margin:30px 0px 50px 0px;
        
    }

    .missionCard h2{
        font-size:32px;
        padding:10px 0px;
        font-weight:600 
    }

    .missionFlex{
        display: flex;
        justify-content: space-between;
        width:100%;
        padding:60px 0px 0px 0px;
        max-width:900px
    }

    .valuesFlex{
        display: flex;
        justify-content: space-between;
    }

    .teamImage{
        width:252px;
        height:220px;
        margin:40px 0px
    }
    .teamMembers{
        display:grid;
        grid-template-columns: 1fr 1fr 1fr 1fr
    }


    
}


@media only screen and (min-width:1600px) {

    .aboutDescription{
        padding:140px 100px;
    }
    .aboutBody{
        max-width: 1500px;
        margin:auto
    }

    .teamImage{
        width:252px;
        height:220px;
        margin:40px 0px
    }
    .teamMembers{
        display:grid;
        grid-template-columns: auto auto auto auto;
        
    }
}
