@media only screen and (max-width: 600px) {
  .BillsnPayHero {
    margin: 150px 0px 0px 0px;
  }
  .productchip {
    color: white;
    background: #fb3640;
    width: 100px;
    border-radius: 100px;
    padding: 6px 25px 6px 20px;
    font-size: 14px;
  }

  .BillsnPayHero h2 {
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    padding: 30px 0px 0px 0px;
    max-width: 470px;
  }

  .websiteButton {
    color: #00b4d8;
    font-weight: 900;
    margin: 36px 0px;
    cursor: pointer;
  }

  .featureCard {
    margin: 30px 0px;
  }

  .featureCard span {
    color: #fb3640;
    font-weight: 500;
  }
  .featureCard h2 {
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    padding: 20px 0px;
    max-width: 190px;
  }

  .featureCard p {
    color: #4b5563;
    max-width: 298px;
  }

  .howContainer{
    padding:120px 0px 60px 0px
  }

  .howContainer .howTitle {
    width: 249px;
    height: 144px;
    font-weight: 600;
    font-size: 50px;
    line-height: 50px;
    color: #0096b4;
  }

  .stepContainer{
    max-width:400px;
    margin:0px 0px 40px 0px
  }

  .stepContainer h2{
    font-size:32px;
    font-weight:600
  }

  .stepContainer p{
    font-size:20px;
    font-weight:500
  }

}

@media only screen and (min-width: 600px) {
  .BillsnPayHero {
    margin: 150px 0px 0px 0px;
  }

  .productchip {
    color: white;
    background: #fb3640;
    width: 99px;
    border-radius: 100px;
    padding: 6px 25px 6px 20px;
    font-size: 14px;
  }

  .BillsnPayHero h2 {
    font-size: 53px;
    font-weight: 600;
    line-height: 53px;
    padding: 30px 0px 0px 0px;
    max-width: 470px;
  }

  .websiteButton {
    color: #00b4d8;
    font-weight: 900;
    margin: 36px 0px;
    cursor: pointer;
  }

  .featureGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .featureCard {
    margin: 30px 0px;
    max-width: 190px;
  }

  .featureCard span {
    color: #fb3640;
    font-weight: 500;
  }
  .featureCard h2 {
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
    padding: 20px 0px;
  }

  .featureCard p {
    color: #4b5563;
    max-width: 470px;
  }

  .howFlex{
    display:flex;
  }

  .howContainer{
    padding:120px 0px 60px 0px
  }

  .howContainer .howTitle {
    width: 249px;
    height: 144px;
    font-weight: 600;
    font-size: 50px;
    line-height: 50px;
    color: #0096b4;
  }

  .stepContainer{
    width:340px;
    margin:0px 0px 40px 0px
  }

  .stepContainer h2{
    font-size:32px;
    font-weight:600
  }

  .stepContainer p{
    font-size:20px;
    font-weight:500
  }
}

@media only screen and (min-width: 768px) {
  .BillsnPayHero {
    margin: 150px 0px 0px 0px;
    display: flex;
  }

  .productchip {
    color: white;
    background: #fb3640;
    width: 100px;
    border-radius: 100px;
    padding: 6px 25px 6px 20px;
    font-size: 14px;
  }

  .BillsnPayHero h2 {
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    padding: 30px 0px 0px 0px;
    max-width: 470px;
  }

  .websiteButton {
    color: #00b4d8;
    font-weight: 900;
    margin: 36px 0px;
    cursor: pointer;
  }

  .featureGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 992px) {
  .BillsnPayHero {
    margin: 100px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
    max-width: auto;
  }

  .billsnPayText {
    margin: 50px 0px 0px 0px;
  }

  .productchip {
    color: white;
    background: #fb3640;
    width: 99px;
    border-radius: 100px;
    padding: 6px 25px 6px 20px;
    font-size: 14px;
  }

  .BillsnPayHero h2 {
    font-size: 55px;
    font-weight: 600;
    line-height: 55px;
    padding: 30px 0px 0px 0px;
    max-width: 470px;
  }

  .websiteButton {
    color: #00b4d8;
    font-weight: 900;
    margin: 36px 0px;
    cursor: pointer;
  }

  .featureGrid {
    display: grid;
    grid-template-columns: auto auto auto;
  }

  .featureCard {
    margin: 30px 0px;
    max-width: 270px;
  }

  .featureCard span {
    color: #fb3640;
    font-weight: 500;
  }
  .featureCard h2 {
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
    padding: 20px 0px;
  }

  .featureCard p {
    color: #4b5563;
    max-width: 470px;
  }

  .howFlex{
    display:flex;
  }

  .howContainer{
    padding:120px 0px 60px 0px
  }

  .howContainer .howTitle {
    width: 249px;
    height: 144px;
    font-weight: 600;
    font-size: 50px;
    line-height: 50px;
    color: #0096b4;
  }

  .stepContainer{
    max-width:280px;
    margin:0px 0px 40px 0px
  }

  .stepGrid{
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:48px
  }

  .stepContainer h2{
    font-size:32px;
    font-weight:600
  }

  .stepContainer p{
    font-size:20px;
    font-weight:500
  }


}

@media only screen and (min-width: 1200px) {
  .BillsnPayHero {
    margin: 100px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
  }

  .billsnPayText {
    margin: 180px 0px 0px 0px;
  }

  .productchip {
    color: white;
    background: #fb3640;
    width: 99px;
    border-radius: 100px;
    padding: 6px 25px 6px 20px;
    font-size: 14px;
  }

  .BillsnPayHero h2 {
    font-size: 60px;
    font-weight: 600;
    line-height: 60px;
    padding: 30px 0px 0px 0px;
    max-width: 470px;
  }

  .websiteButton {
    color: #00b4d8;
    font-weight: 900;
    margin: 36px 0px;
    cursor: pointer;
  }

  .howFlex{
    display:flex;
  }

  .howContainer{
    padding:120px 0px 60px 0px
  }

  .howContainer .howTitle {
    width: 249px;
    height: 144px;
    font-weight: 600;
    font-size: 50px;
    line-height: 50px;
    color: #0096b4;
  }

  .stepContainer{
    max-width:250px;
    margin:0px 0px 40px 0px
  }

  .stepGrid{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .stepContainer h2{
    font-size:32px;
    font-weight:600
  }

  .stepContainer p{
    font-size:20px;
    font-weight:500
  }
}









.floating {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    color: white;
  }
  @keyframes floating {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, 15px);
    }
    100% {
      transform: translate(0, -0px);
    }
  }