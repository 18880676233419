
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .productsContainer{
        text-align: center;
    }

    .productsContainer h2{
       
        font-weight:600;
        margin:30px 0px 10px 0px
    } 

    .productsContainer p{
        font-size:15px !important;
        width:100%;

    }

    .productDescription{
        padding:0px 16px
    }
    
    

}

@media only screen and (min-width: 600px) {
    .productsContainer{
        text-align: center;
    }

    .productsContainer h2{
      
        font-weight:600;
    } 

    .productsContainer p{
        font-size:15px !important;
    
    }

    .productDescription{
        max-width: 582px;
        margin: auto;
    }
    

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .productsContainer{
        text-align: center;
    }

    .productsContainer h2{
        /* font-size:35px; */
        font-weight:600
    } 

    .productsContainer p{
        font-size:15px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .productsContainer{
        text-align: center;
    }

    .productsContainer h2{
        font-weight:600;
    } 

    .productsContainer p{
        font-size:15px !important;

    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .productsContainer{
        text-align: center;
    }

    .productsContainer h2{
        
        font-weight:600;
    } 

    .productsContainer p{
        font-size:20px !important;
        
    }

}

