@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face{
  font-family: "PP Radio";
  src:url("./fonts/PPRadioGrotesk-Regular.otf")
}

body {
  margin: 0;
  font-family: "PP Radio";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth
}

code {
  font-family: "PP Radio";
}
