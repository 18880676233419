.active {
  color: #00b4d8;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

.menuItems li {
  list-style-type: none;
  margin: 0px 0px 0px 56px;
  cursor: pointer;
  font-weight: 500;
}

.menuItems li:hover {
  color: #00b4d8;
}

.logoPosition {
  margin: 0px 0px 0px 0px;
}

.overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 20px;
  color: white;
  display: block;
  transition: 0.3s;
}

.overlay-content .menuLink {
  color: white !important;
  font-size: 20px;
}

.productMenu {
  color: white;
  margin: 10px auto;
  display: none;
  transition: display 2s;
  transition-timing-function: ease;
}

.productMenu li {
  margin: 13px 0px;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay button:hover,
.overlay button:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

.activeMenu {
  background: #0066f5;
  font-size: 5px;
  padding: 0px 1px 1px 1px;
  border-radius: 50%;
  color: #0066f5;
}

.peopleactiveMenu {
  color: #0077ff;
}

.notactiveMenu {
  display: none;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

@media screen and (max-width: 1201px) {
  .largescreen {
    display: none;
  }
}

@media screen and (min-width: 1201px) {
  .mobilemenudisplay {
    display: none;
  }
  /* .fixedNav{
      position: fixed;
      width:90%;
      background-color: white;
    } */
}

@media screen and (min-width: 1025px) {
  .togglePosition {
    margin: 0px 0px 40px 100px;
  }
  .buttonSpace {
    margin-right: 20px;
  }
}

.menuBackground {
  background: white;
  transition: 600ms ease-in;
}

.navbar {
  overflow: show;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 40;
  padding: 11px 0px;
  transition: 0.2s;

}

.navShadow {
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
}

@media screen and (min-width: 1900px) {
  .navbar {
    overflow: show;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

.dropdown {
  /* position: relative; */
  display: inline-block;
  z-index: 40;
}

.dropdown-developers {
  display: none;
  position: absolute;
  background-color: #edfafc;
  padding: 40px 0px 10px 0px;
  width: 100vw;
  border-radius: 10px;
  z-index: 10;
  margin: 4px 0px 0px 0px;
  left: 0;
}

.dropdown-developers button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  font-weight: 500;
}

.dropdown-developers button:hover {
  color: #00b4d8;
}

.dropdown:hover .dropdown-products,
.dropdown:hover .mobile-dropdown-products {
  display: block;
}

.dropdown:focus,
.dropdown:active {
  display: block;
}

.dropdown:hover .dropdown-developers,
.dropdown:hover .mobile-dropdown-developers {
  display: block;
}

.dropdown:hover .dropdown-about,
.dropdown:hover .mobile-dropdown-about {
  display: block;
}

.menuItemContainer {
  width: 228px;
  padding: 10px 10px;
}

.menuItemTitle {
  text-align: start;
  width: 228px;
  height: 40px;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #0096b4;
}

.menuItemDescription {
  text-align: start;
}

@media only screen and (min-width: 1600px) {
  .menuItemStyle {
    justify-content: center;
  }
}
