.tabsContainer{
    width:100%
}

.tabsContainer li{
    margin:0px 30px 0px 0px;
    width:16%;
    text-align: center;
    ;
}


.tabsContainer li a{
    font-weight:600;
    border-radius:100px;
    
   
}


.tabsContainer li a:focus{
    background:#4B5563;
    font-weight:600;
    outline:0;
    color:white

}

.tabsActive,.tabsContainer li a:active{
    background:#4B5563;
    font-weight:600;
    outline:0;
    color:white

}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .tabSelect{
        background:#F3F4F6;
        border-radius:100px;
       
    }

    .flowOver{
        overflow-x: scroll !important;
    }
    .tabsContainer li{
        
        width:100px;
        text-align: center;
    }

    .tabsTitle{
        font-size:25px;
        width:100%;
        text-align:start
    }
    .tabDescription{
        width:100% !important;
        text-align:start
    }

    
    

}

@media only screen and (min-width: 600px) {
    .tabsTitle{
        font-size:27px;
        width:60%
    }

    .tabImage{
        width:500px !important;
        height:388px !important
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .tabsTitle{
        font-size:27px;
        width:60%
    }

    .tabCardContainer{
        max-width:730px;
        padding:0px 0px;
        margin:auto
    }

    .tabCardContainer p{
        width:70%;
    }

    .tabCardContainer h1{
        width:80%;
    }

    .tabImage{
        width:500px !important;
        height:388px !important
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .tabsTitle{
        font-size:27px;
        width:60%
    }

    .tabImage{
        width:500px !important;
        height:388px !important
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .tabsTitle{
        font-size:40px;
        width:80%
    }

    .tabDescription{
        width:70% !important
    }

    .tabImage{
        width:800px !important;
        height:388px !important
    }

    .tabCardContainer{
        max-width:1060px;
        margin:auto
    }

}


.tabSelect{
    background:#F3F4F6;
    border-radius:100px;
    padding: 0px 0px 0px 30px
}
