.cookieContainer {
    background:#1db1cf;
    padding:15px 10px 30px 10px;
    position:fixed;
    bottom:0;
    color:white;
    z-index: 50;
    width:100%
}




@media only screen and (max-width:600px){
   .cookieText{
    padding:19px 0px
   }

   .cookieButton {
    display:flex
   }

   .cookieButton > *{
     margin:0px 20px 0px 0px
   }

   .cookieBanner {
    padding:0px 0px 0px 15px
}

}


@media only screen and (min-width:600px){
    .cookieText{
        padding:19px 0px;
        max-width:560px
       }
    
       .cookieButton {
        display:flex
       }
    
       .cookieButton > *{
         margin:0px 20px 0px 0px
       }

       .cookieBanner {
        padding:0px 0px 0px 35px
    }

}




@media only screen and (min-width:768px){
    
    .cookieBanner {
        padding:0px 0px 0px 35px
    }

}


@media only screen and (min-width:894px){
    .cookieBanner {
        padding:0px 0px 0px 35px
    }

    .cookieButton {
       margin-top:30px 
       }
}



@media only screen and (min-width:992px){
    .cookieBanner{
        display:grid;
        grid-template-columns: 65% 35%;
    }
}



@media only screen and (min-width:1200px){
    .cookieText{
        max-width:700px
       }

    .cookieBanner{
        display:grid;
        grid-template-columns: 70% 30%;
    }
    .cookieContainer {
        background:#1db1cf;
        padding:10px 10px 10px 10px;
        position:fixed;
        bottom:0;
        color:white;
        z-index: 50;
        width:100%
    }

    .cookieBanner {
        padding:0px 80px 0px 89px
    }

    .cookieButton {
        margin-top:20px 
        }
}



@media only screen and (min-width:1500px){
    .cookieButton {
        margin-top:20px 
        }
    .cookieBanner {
        max-width:1500px;
        margin:auto
    }
}